import Utils from "../../Utils";
import {
  CountJobModel,
  statusChangeModel,
  serviceProviderModel,
} from "../../model";

export const CountJobReducer = (
  state: CountJobModel = new CountJobModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.COUNT_JOB:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const statusChangeReducer = (
  state: statusChangeModel = new statusChangeModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.STATUS_CHANGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const serviceProviderReducer = (
  state: serviceProviderModel = new serviceProviderModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SERVICE_PROVIDER_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

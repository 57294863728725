// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB3LvVZu4ROBd4YLfemx0ZaI-nuZE0fKTw",
  authDomain: "infinte-trades.firebaseapp.com",
  projectId: "infinte-trades",
  storageBucket: "infinte-trades.appspot.com",
  messagingSenderId: "557288765652",
  appId: "1:557288765652:web:6ebcf0d98420b57ede2341",
  measurementId: "G-LBN5RNZCZ8",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const getpushNotificationToken = (setTokenFound: any) => {
  return getToken(messaging, {
    vapidKey:
      "BCVQJwxXv-LyIETC_kzPY06ee8MGtEcdPu4CvEbZVCbX6fWop55HlyG0B8ciYjZj-EGzxe7inkDoyGjc2-GWgag",
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("fb", currentToken);
        setTokenFound(true);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const makeToken = () => {
  return new Promise((res, rej) => {
    getToken(messaging, {
      vapidKey:
        "BCVQJwxXv-LyIETC_kzPY06ee8MGtEcdPu4CvEbZVCbX6fWop55HlyG0B8ciYjZj-EGzxe7inkDoyGjc2-GWgag",
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("fb", currentToken);
          res(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          rej("deviceToken");
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
        rej("deviceToken");
      });
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

const ActionName = {
  LOADING: "LOADING",
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SIGNUP: "SIGNUP",
  PROFILECOMPLETE: "PROFILECOMPLETE",
  PROFILE_DETAILS_DATA: "PROFILE_DETAILS_DATA",
  ROLES: "ROLES",
  EDIT_MYPROFILE: "EDIT_MYPROFILE",
  EDITBUSINESSPROFILE: "EDITBUSINESSPROFILE",
  ADMIN_TEAM_PERMISSION: "ADMIN_TEAM_PERMISSION",
  ADMIN_LIST: "ADMIN_LIST",
  EDIT_PERMISSION: "EDIT_PERMISSION",
  SEARCH: "SEARCH",
  SERVICE_PROVIDER_SERACH: "SERVICE_PROVIDER_SERACH",
  SERVICE_PROVIDER_SEARCH_RESET: "SERVICE_PROVIDER_SEARCH_RESET",
  RFQ_PROJECT: "RFQ_PROJECT",
  CLIENT_JOBS: "CLIENT_JOBS",
  JOB_DETAILS: "JOB_DETAILS",
  OTHER_JOB_DETAIL: "OTHER_JOB_DETAIL",
  JOB_ID: "JOB_ID",
  TAB_VALUE: "TAB_VALUE",
  DELETE_JOB: "DELETE_JOB",
  BOOKED_DETAILS: "BOOKED_DETAILS",
  COUNT_JOB: "COUNT_JOB",
  STATUS_CHANGE: "STATUS_CHANGE",
  GETSTARTED_MODAL_OPEN: "GETSTARTED_MODAL_OPEN",
  GETSTARTED_MODAL_CLOSE: "GETSTARTED_MODAL_CLOSE",
  GETSTARTED_TOOLTIP_OPEN: "GETSTARTED_TOOLTIP_OPEN",
  GETSTARTED_TOOLTIP_CLOSE: "GETSTARTED_TOOLTIP_CLOSE",
  GETSTARTED_STEPS_COMPANY: "GETSTARTED_STEPS_COMPANY",
  SWITCH_SEARCH_RESULT: "SWITCH_SEARCH_RESULT",
  SERVICE_PROVIDER_LIST: "SERVICE_PROVIDER_LIST",
  RATE_DETAILS: "RATE_DETAILS",
  SCHEDULE_BOOKING: "SCHEDULE_BOOKING",
  SAVED_COMPANY_LISTING: "SAVED_COMPANY_LISTING",
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  COMAPNY_DETAILS: "COMPANY_DETAILS",
  COMPANY_SEARCH_FROM_BOOK_NOW: "COMPANY_SEARCH_FROM_BOOK_NOW",
  GET_COMMENTS: "GET_COMMENTS",
};

export default ActionName;

import Utils from "../../../../Utils";
import { uploadFile } from "./../../../../Utils/AwsFileUpload";
import {
  globalLoaderStart,
  globalLoaderEnd,
} from "../../../../reducer/globalReducer";

const getBusinessProfile = () => (dispatch: any) => {
  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(2, "Please check your internet connection!");
    return;
  }

  dispatch(globalLoaderStart());

  Utils.API.getApiCall(
    Utils.endPoint.profile,
    "",
    (res: any) => {
      const { data } = res;

      if (data.statusCode === Utils.Constants.api_success_code.success) {
        localStorage.setItem(
          "user_name",
          data?.data?.clientDetail?.businessName ||
            data?.data?.clientDetails[0]?.clientDetail?.businessName
        );
        dispatch({
          type: Utils.ActionName.PROFILE_DETAILS_DATA,
          payload: {
            profileDetailsData: data?.data,
          },
        });
        dispatch({
          type: "GET_CURRENT_BUSINESS_PROFILE",
          payload: {
            businessName:
              data?.data?.clientDetail?.businessName ||
              data?.data?.clientDetails[0]?.clientDetail?.businessName ||
              "",
            businessTagline: data?.data?.clientDetail?.businessTagline
              ? data?.data?.clientDetail?.businessTagline ||
                data?.data?.clientDetails[0].clientDetail?.businessTagline
              : "",

            businessProfilePicture: data?.data?.clientDetail
              ?.businessProfilePicture
              ? data?.data?.clientDetail?.businessProfilePicture ||
                data?.data?.clientDetails[0]?.clientDetail
                  ?.businessProfilePicture
              : "",

            location:
              data?.data?.location ||
              data?.data?.clientDetails[0]?.location ||
              "",
          },
        });
        dispatch({
          type: Utils.ActionName.NOTIFICATION_LIST,
          payload: {
            businessName:
              data?.data?.clientDetail?.businessName ||
              data?.data?.clientDetails[0]?.clientDetail?.businessName ||
              "",
          },
        });
      }
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;

      Utils.showAlert(2, data.message);
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      dispatch(globalLoaderEnd());
    }
  );
};

const editBusinessProfile =
  (businessProfileData: any) => async (dispatch: any) => {
    if (!navigator.onLine) {
      //check if user is online or not
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.LOADING,
        payload: false,
      });
      return;
    }

    if ("businessProfilePicture" in businessProfileData) {
      try {
        const imageData: any = await uploadFile(
          businessProfileData["businessProfilePicture"]
        );
        businessProfileData["businessProfilePicture"] = imageData.Location;
      } catch (error) {
        console.log(error);
        Utils.showAlert(2, "Error Occured while uploading image");
        return;
      }
    }

    if ("images" in businessProfileData) {
      let imagesUrl = [];

      for (let el of businessProfileData["images"]) {
        try {
          const imageData: any = await uploadFile(el.file);
          Utils.showAlert(1, `${el.file.name} is uploaded Successfully`);
          imagesUrl.push(imageData.Location);
        } catch (error) {
          console.log(error);
          Utils.showAlert(2, "Error Occured while uploading image");
          throw new Error("Error Occured while uploading image");
        }
      }

      delete businessProfileData["images"];
      businessProfileData["addImages"] = imagesUrl;
    }

    businessProfileData["clientId"] = localStorage.getItem("clientId")
      ? localStorage.getItem("clientId")
      : localStorage.getItem("user_id");

    dispatch(globalLoaderStart());

    Utils.API.putApiCall(
      Utils.endPoint.editBusinessProfile,
      businessProfileData,
      (res: any) => {
        const { data } = res;
        if (data.statusCode === Utils.Constants.api_success_code.success) {
          console.log(data);
          Utils.showAlert(1, "Profile updated successfully");
          dispatch(getBusinessProfile());
        }
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error);
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export { getBusinessProfile, editBusinessProfile };

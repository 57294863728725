import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Avatar,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Utils from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessProfile } from "../../screen/dashboard/settings/businessProfile/action";
import { ReducersModel } from "../../model";
export default function HeaderModal({ open, handleClose, setLogoutOpen }: any) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState(false);
  const [expand1, setExpand1] = React.useState(false);

  React.useEffect(() => {
    dispatch(getBusinessProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { profileDetailsData } = useSelector(
    (state: ReducersModel) => state.profiledetailsdataReducer
  );

  return smallScreen ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          background: "#f6f6f6",
          position: "relative",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "24px",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
            background: "#f6f6f",
          }}
          height={"68px"}
        >
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: "1.5rem 1.5rem 1.5rem 1.5rem",
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{
              paddingBottom: "26px",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            {profileDetailsData &&
            profileDetailsData.clientDetail &&
            profileDetailsData.clientDetail.businessProfilePicture ? (
              <Avatar
                src={
                  (profileDetailsData &&
                    profileDetailsData.clientDetail &&
                    profileDetailsData.clientDetail.businessProfilePicture) ||
                  "String"
                }
                sx={{
                  height: "24px",
                  width: "24px",
                  marginRight: "12px",
                }}
              />
            ) : (
              <AccountCircleOutlinedIcon
                sx={{
                  fontSize: "1.5rem",
                  marginRight: "12px",
                }}
              />
            )}
            <Typography
              sx={{
                // borderBottom: "1px solid #e0e0e0",
                color: "#333333",
                fontSize: "18px",
              }}
            >
              {localStorage.getItem("user_name")}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                paddingY: "32px",
                borderBottom: "1px solid #e0e0e0",
                cursor: "pointer",
                color: "#333333",
                fontSize: "18px",
              }}
              onClick={() => {
                history.push(Utils.Pathname.DASHBOARD);
                handleClose();
              }}
            >
              Dashboard
            </Typography>
          </Stack>
          <Stack>
            <Accordion
              onChange={(event, expanded) => {
                setExpand(expanded);
              }}
              sx={{
                paddingY: "32px",
                borderBottom: "1px solid #e0e0e0",
                boxShadow: "none",
                background: "#f6f6f6",
              }}
              expanded={expand}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  Requests
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  // padding: 0,
                  cursor: "pointer",
                  marginTop: "32px",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.HEADER.REQUESTS);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  My Requests
                </Typography>
              </AccordionDetails>
              {/* <AccordionDetails
                sx={{
                  cursor: "pointer",
                  marginTop: "32px",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.SAVEDCOMPANY);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  Saved Providers
                </Typography>
              </AccordionDetails> */}
              <AccordionDetails
                sx={{
                  // padding: 0,
                  cursor: "pointer",
                  marginTop: "32px",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.Enquiries);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  Enquiries
                </Typography>
              </AccordionDetails>
              {/* <AccordionDetails
                sx={{
                  marginTop: "32px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.landing);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  All Providers
                </Typography>
              </AccordionDetails> */}
            </Accordion>
          </Stack>

          <Stack>
            <Accordion
              onChange={(event, expanded) => {
                setExpand1(expanded);
              }}
              sx={{
                paddingY: "32px",
                borderBottom: "1px solid #e0e0e0",
                boxShadow: "none",
                background: "#f6f6f6",
              }}
              expanded={expand1}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  Providers
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  // padding: 0,
                  cursor: "pointer",
                  marginTop: "32px",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.landing);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  All Providers
                </Typography>
              </AccordionDetails>
              <AccordionDetails
                sx={{
                  cursor: "pointer",
                  marginTop: "32px",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.SAVEDCOMPANY);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  Saved Providers
                </Typography>
              </AccordionDetails>
              {/* <AccordionDetails
                sx={{
                  marginTop: "32px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push(Utils.Pathname.landing);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "18px",
                  }}
                >
                  All Providers
                </Typography>
              </AccordionDetails> */}
            </Accordion>
          </Stack>

          {/* <Stack
            sx={{
              paddingY: "32px",
              borderBottom: "1px solid #e0e0e0",
            }}
            direction="row"
            justifyContent={"space-between"}
            onClick={() => {
              history.push(Utils.Pathname.landing);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              All Providers
            </Typography>
          </Stack> */}

          <Stack
            sx={{
              paddingY: "32px",
              borderBottom: "1px solid #e0e0e0",
            }}
            direction="row"
            justifyContent={"space-between"}
            onClick={() => {
              history.push(Utils.Pathname.Settings.Notification);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              Notifications
            </Typography>
            <NotificationsNoneOutlinedIcon />
          </Stack>
          <Stack
            sx={{
              paddingTop: "32px",
              paddingBottom: expand ? "32px" : "90px",
              borderBottom: "1px solid #e0e0e0",
              cursor: "pointer",
            }}
            direction="row"
            justifyContent={"space-between"}
            onClick={() => {
              history.push(Utils.Pathname.Settings?.Mydetails);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "18px",
              }}
            >
              Settings
            </Typography>
            <SettingsOutlinedIcon />
          </Stack>
          <Stack
            onClick={() => {
              setLogoutOpen(true);
              handleClose();
            }}
            // paddingY={"32px"}
            direction="row"
            spacing={2}
            sx={{
              paddingY: "32px",
              cursor: "pointer",
            }}
          >
            <ExitToAppIcon />
            <Typography>Logout</Typography>
          </Stack>
        </Box>
      </Box>
    </Modal>
  ) : null;
}

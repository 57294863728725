import Utils from "../../../../Utils";
import { ClientJobsTableModel } from "../../../../model";

export const clientJobsTableReducer = (
  state: ClientJobsTableModel = new ClientJobsTableModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CLIENT_JOBS:
      return { ...state, ...action.payload };
    case "All":
      return { ...state, ...action.payload };
    case "Emergency_Callout":
      return { ...state, ...action.payload };
    case "Maintenance_Service":
      return { ...state, ...action.payload };
    case "Project_Services":
      return { ...state, ...action.payload };
    case "RFQ":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

import { CompanyClientListReducer } from "./../screen/LandingPage/reducer";
import { combineReducers } from "redux";
import {
  globalLoaderReducer,
  getStartedModalReducer,
} from "../reducer/globalReducer";
import {
  logInReducer,
  forgotPasswordReduce,
  resetPasswordReduce,
} from "../screen/login/reducer";
import {
  adminTeamPermissionReducer,
  teamMembersDataReducer,
  deleteadminTeamPermissionReducer,
  editadminTeamPermissionReducer,
} from "../screen/dashboard/settings/Team/reducer";
import { signupReducer, profileReducer } from "../screen/signup/reducer";
import { dropDownReducer } from "./globalReducer";
import { MyDetailsReducer } from "./../screen/dashboard/settings/MyDetails/reducer";
import {
  BusinessProfileReducer,
  profiledetailsdataReducer,
} from "./../screen/dashboard/settings/businessProfile/reducer";
import {
  refProjectReducer,
  serviceProviderSearchReducer,
} from "../screen/dashboard/RFQ/reducer";
import { clientJobsTableReducer } from "./../screen/dashboard/Home/Requests/reducer";
import { jobDetailsReducer } from "../screen/viewDetails/reducer";
import { CountJobReducer } from "../screen/Requests/reducer";
import {
  statusChangeReducer,
  serviceProviderReducer,
} from "../screen/Requests/reducer";
import { getStartedStepsCompanyReducer } from "../screen/dashboard/Home/GetStarted/reducer";
import {
  ratesDetailsReducer,
  companySearchReducer,
} from "../screen/dashboard/Project_Services/reducer";
import { scheduleBookingReducer } from "../screen/dashboard/Home/Booking/reducer";
import { savedCompanyListingReducer } from "./../screen/LandingPage/reducer";
import { notificationListingReducer } from "../components/header/reducer";
import { companyDetailsReducer } from "./../screen/LandingPage/reducer";
import { commentsModelReducer } from "../screen/viewDetails/reducer";

const rootReducer = combineReducers({
  globalLoaderReducer,
  logInReducer,
  forgotPasswordReduce,
  resetPasswordReduce,
  signupReducer,
  profileReducer,
  profiledetailsdataReducer,
  dropDownReducer,
  MyDetailsReducer,
  BusinessProfileReducer,
  adminTeamPermissionReducer,
  teamMembersDataReducer,
  deleteadminTeamPermissionReducer,
  editadminTeamPermissionReducer,
  serviceProviderSearchReducer,
  refProjectReducer,
  clientJobsTableReducer,
  jobDetailsReducer,
  CountJobReducer,
  statusChangeReducer,
  getStartedStepsCompanyReducer,
  getStartedModalReducer,
  serviceProviderReducer,
  ratesDetailsReducer,
  scheduleBookingReducer,
  CompanyClientListReducer,
  savedCompanyListingReducer,
  notificationListingReducer,
  companyDetailsReducer,
  companySearchReducer,
  commentsModelReducer,
});

export default rootReducer;

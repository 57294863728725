import Utils from "../../Utils";
import { CommentsModel, JobDetailsModel } from "../../model";

export const jobDetailsReducer = (
  state: JobDetailsModel = new JobDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.JOB_DETAILS:
      return { ...state, ...action.payload };
    case Utils.ActionName.OTHER_JOB_DETAIL:
      return { ...state, ...action.payload };
    case Utils.ActionName.JOB_ID:
      return { ...state, ...action.payload };
    case Utils.ActionName.TAB_VALUE:
      return { ...state, ...action.payload };
    case Utils.ActionName.BOOKED_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const commentsModelReducer = (
  state: CommentsModel = new CommentsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_COMMENTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

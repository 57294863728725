import { makeStyles } from "@mui/styles/";

const useStyles = makeStyles(() => ({
  container: ({ theme }: any) => ({
    width: "100%",
    background: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("1200")]: {
      margin: "0",
      width: "100%",
    },
  }),

  loginContainer: ({ theme }: any) => ({
    width: "40%",
    height: "100%",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down(1025)]: {
      margin: "0 auto",
      width: "100%",
    },
    [theme.breakpoints.between(800, 850)]: {
      height: "auto",
    },

    [theme.breakpoints.between(1025, 1200)]: {
      height: "auto",
    },
    "& >div": {
      display: "flex",
      width: "100%",
      height: "100%",
    },
  }),
}));

export { useStyles };

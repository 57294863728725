import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Backdrop, CircularProgress, Theme } from "@mui/material";
import { useSelector } from "react-redux";
import { ReducersModel } from "../../model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.light,
      minHeight: "100vh",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "var(--white)",
    },
  })
);

function GlobalLoader() {
  const classes = useStyles();
  const { isLoading } = useSelector(
    (state: ReducersModel) => state.globalLoaderReducer
  );

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default GlobalLoader;

import { NotificationListingModel } from "../../model";
import Utils from "../../Utils";

export const notificationListingReducer = (
  state: NotificationListingModel = new NotificationListingModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.NOTIFICATION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const LocalImages = {
  Image1: "../images/image1.png",
  Image2: "../images/image2.png",
  Image3: "../images/image3.png",
  Image4: "../images/image4.png",
  // Construction: "../images/construction.jpeg",
  Toolkit: "../images/toolkit.jpeg",
  Boot: "../images/boot.jpeg",
  // Hand: "../images/Hand.jpg",
  Plan: "../images/plan.jpeg",
  // Build: "../images/build.png",
  Logo: "../images/logo.png",
  // Gun: "../images/gun.jpeg",
  // Man: "../images/man.jpeg",
  Notification_dot: "../icon/notification_dot.png",
  Logout: "../icon/logout.png",
  Message: "../icon/message.png",
  Arrow: "../icon/arrow.png",
  User: "../icon/user.png",
  User1: "../icon/user1.png",
  Frame: "../icon/Frame.png",
  MutiUser: "../icon/multiUser.png",
  Company_Profile: "../icon/company_profile.png",
  Agreements: "../icon/agreements.png",
  Teams: "../icon/team.png",
  Notifications: "../icon/notification.png",
  Notifications_dot: "../icon/redbellIcon.png",
  Menu: "../icon/menu.png",
  ArrowLeft: "../icon/arrowLeft.png",
  ArrowRight: "../icon/arrowRight.png",
  Attached: "../icon/attached.png",
  Rectangle: "../icon/Rectangle.png",
  Circle: "../icon/circle.png",
  Upload: "../icon/upload.png",
  delete: "../icon/delete.svg",
  delete1: "../icon/delete.png",
  request: "../icon/request.svg",
  booking: "../icon/booking.jpg",
  Filter: "../icon/filter.png",
  Search: "../icon/search.png",
  BlueSearch: "../icon/blueSearch.png",
  Plus: "../icon/plus.png",
  pdfIcon: "../icon/pdfIcon.png",
  pngIcon: "../icon/png.png",
  DocIcon: "../images/docIcon.jpeg",
  ExcelIcon: "../images/excelIcon.png",
  TSLogo: "../images/TSLogo.jpg",
  A2Milk: "../images/A2Milk.jpg",
  HomePageBackground: "../images/allcompanies.png",
  JpegFile: "../images/JpegFile.png",
  Vertical_Dot: "../icon/vertical_dot.png",
  Horizontal_Dot: "../icon/horizontal_dot.png",
  LeftArrow1: "../icon/LeftArrow1.png",
  RightArrow1: "../icon/RightArrow2.png",
  Error: "../icon/error.png",
  QuestionMark: "../icon/question.png",
  reuse: "../icon/reuse.png",
  ToolBox: "../icon/tool-box.png",
  Calender: "../icon/calender.png",
  PdfIconWhite: "../icon/pdficonwhite.png",
  magnifier: "../icon/magnifier.png",
  Clock: "../icon/clock.png",
  location: "../icon/location.png",
  filterHomepage: "../icon/filter.png",
  Vector: "../icon/Vector.png",
  Dollor: "../icon/dollor.png",
  Inbox: "../icon/inbox.png",
  Trash: "../icon/Trash.png",
  BlueDot: "../icon/blue_dot.png",
  MarkRead: "../icon/readMark.png",
  Remove: "../icon/remove.png",
  Phone: "../icon/phone.png",
  Previous: "../icon/previous.png",
  Next: "../icon/next.png",
  AvatarIcon: "/images/AvatarIcon.png",
  noComments: "/images/noComments.png",
  Add: "/images/add.png",
  crossIcon: "/images/crossIcon.png",
  ellipse: "/images/Ellipse.png",
  blueup: "/images/blueUp.png",
  infoIcon: "../icon/info.svg",
};
export default LocalImages;

import Utils from "../../../Utils";
import {
  CompanySearchFromBookNowModel,
  RatesDetailsModel,
} from "../../../model";

export const ratesDetailsReducer = (
  state: RatesDetailsModel = new RatesDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.RATE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const companySearchReducer = (
  state: CompanySearchFromBookNowModel = new CompanySearchFromBookNowModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.COMPANY_SEARCH_FROM_BOOK_NOW:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

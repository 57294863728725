import Utils from ".";

export const setAuthorizationToken = (token: string | null) => {
  if (token) {
    Utils.Constants.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }
};

export const getAccessToken = () => {
  let accessToken = sessionStorage.getItem("access_token");

  return accessToken;
};

export const setAccessToken = (accessToken: any) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

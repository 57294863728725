import ActionName from "../../../../Utils/actionName";

function getStartedStepsCompanyReducer(
  state: any = {
    isCompanyProfileComplete: false,
    isInviteCoworker: false,
    isBookingTradeService: false,
    isFinancialOnboard: false,
    isShow: false,
  },
  action: any
) {
  switch (action.type) {
    case ActionName.GETSTARTED_STEPS_COMPANY:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

const getStartedStepsCompanyState = (state: any) =>
  state?.getStartedStepsCompanyReducer;

export { getStartedStepsCompanyReducer, getStartedStepsCompanyState };

const Roles = {
  ADMIN: "ADMIN",
  SUPERADMIN: "SUPERADMIN",
};

const UserTypes = {
  CLIENT: "CLIENT",
};

export { Roles, UserTypes };

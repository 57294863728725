import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LocalImages from "../../Utils/localImages";
import { Carousel } from "react-responsive-carousel";
import ImageContainer from "../ImageContainer";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  sliderWrapper: ({ theme }: any) => ({
    width: "48%",
    [theme.breakpoints.down("1025")]: {
      display: "none",
    },
  }),

  imageContainer: () => ({}),
  carouselImage: () => ({
    "& figure": {
      margin: 0,

      height: "100vh",
    },
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
  }),
}));
const Slider = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop
        autoPlay
        useKeyboardArrows
        transitionTime={1000}
        className={classes.sliderWrapper}
      >
        <div className={classes.imageContainer}>
          <ImageContainer
            style={classes.carouselImage}
            imgUrl={LocalImages.Image1}
          />
        </div>
        <div className="slide-holder">
          <ImageContainer
            style={classes.carouselImage}
            imgUrl={LocalImages.Image2}
          />
        </div>
        <div className="slide-holder">
          <ImageContainer
            style={classes.carouselImage}
            imgUrl={LocalImages.Image3}
          />
        </div>
        <div className="slide-holder">
          <ImageContainer
            style={classes.carouselImage}
            imgUrl={LocalImages.Image4}
          />
        </div>
      </Carousel>
    </>
  );
};

export default Slider;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  headerContainer: () => ({
    position: "absolute",
    display: "flex",
    marginBottom: "25%",
    flexDirection: "row",
  }),
  logo: ({ theme }: any) => ({
    height: "40px",
    width: "132px",
    marginLeft: "50px",
    marginRight: "8%",
    [theme.breakpoints.down(1025)]: {
      marginLeft: "3%",
    },
  }),
  headerbutton: () => ({
    "&.MuiButton-root": {
      background: "transparent",
      color: "#BDBDBD",
      fontSize: "16px",
      fontWeight: "600",
      marginRight: "15px",
      "&:hover": {
        color: "#4F4F4F",
        background: "none",
      },
      "&:active": {
        background: "transparent",
      },
    },
  }),
  icon: ({ theme }: any) => ({
    display: "flex",
    marginLeft: "286px",
    [theme.breakpoints.between(900, 1195)]: {
      marginLeft: "40px",
    },
  }),
  notification_dot: ({ theme }: any) => ({
    height: "20px",
    width: "16px",
    color: "#4F4F4F",
    marginTop: "8px",
    marginLeft: "150px",
    marginRight: "35px",
    [theme.breakpoints.down(1025)]: {
      height: "15px",
      width: "12px",
      marginTop: "5px",
      marginRight: "40px",
    },
    [theme.breakpoints.down(300)]: {
      marginLeft: "50px",
    },
  }),
  notificationBackground: () => ({
    height: "40px",
    width: "40px",
    backgroundColor: "#fff",
    borderRadius: "50%",
  }),
  message: ({ theme }: any) => ({
    height: "18px",
    width: "18px",
    color: "#4F4F4F",
    marginTop: "12px",
    [theme.breakpoints.down(1025)]: {
      height: "13.5px",
      width: "13.5px",
      marginTop: "10px",
      marginRight: "40px",
    },
  }),
  arrow: () => ({
    height: "7.42px",
    width: "12.02px",
    color: "#4F4F4F",
    marginTop: "23px",
    marginLeft: "10px",
  }),
  menu: ({ theme }: any) => ({
    height: "12px",
    width: "18px",
    color: "#4F4F4F",
    marginLeft: "20px",
    marginTop: "5px",
    [theme.breakpoints.down(600)]: {
      marginRight: "0px",
      marginTop: "5px",
    },
  }),
  user: () => ({
    height: "14.25px",
    width: "12px",
    color: "#4F4F4F",
    marginTop: "6px",
  }),

  dontHaveAccount: () => ({
    "& a": {
      textDecoration: "none",
    },
  }),

  header: () => ({
    display: "flex",
    marginBottom: "10px",
  }),

  settings: () => ({
    display: "flex",
    flexDirection: "column",
    marginLeft: "100px",
    alignItems: "start",
  }),

  set: () => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  }),

  title: () => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#4F4F4F",
  }),
  user1: () => ({
    width: "16px",
    height: "19px",
    color: "#4F4F4F",
    marginRight: "10px",
  }),
  sidetext: () => ({
    "& a": {
      textDecoration: "none",
      color: "#000000",
    },
  }),
  activeButton: () => ({
    color: "#ffffff",
    backgroundColor: "#0da4ce",
    maxHeight: "40px",
  }),
  dontHaveAccount1: () => ({
    "& a": {
      textDecoration: "none",
      color: "#4F4F4F",
      marginLeft: "1%",
    },
  }),
  lables: () => ({
    float: "left",
    marginTop: "1.5%",
    marginBottom: "1.5%",
  }),
  titlebutton: () => ({
    "&.MuiButton-root": {
      color: "#828282",
      fontSize: "16px",
      fontWeight: "500",
      textTransform: "capitalize",
      marginTop: "5px",
      "&:hover": {
        background: "#9EDBEB",
      },
    },
  }),

  title1: () => ({
    fontSize: "32px",
    color: "#333333",
    textAlign: "start",
    fontWeight: "600",
    marginTop: "5%",
  }),
  title2: () => ({
    fontSize: "16px",
    color: "#828282",
    textAlign: "start",
    fontWeight: "400",
    marginTop: "2%",
  }),

  inputfield: () => ({}),

  project: () => ({
    fontSize: "16px",
    fontWeight: "500",
    color: "#424546",
    marginTop: "3%",
    marginBottom: "2%",
  }),
  projectTitle: () => ({
    fontSize: "14px",
    fontWeight: "400",
    color: "#4F4F4F",
    marginTop: "2%",
    marginBottom: "2%",
  }),
  modalBox: ({ theme }: any) => ({
    width: "334px",
    minHeight: "100px",
    height: "390px",
    backgroundColor: "#fff",
    outline: "none",
    position: "relative",
    top: "4%",
    left: "2%",
    padding: "1.6rem 1.5rem",
    "&.MuiBackdrop-root-MuiModal-backdrop": {
      background: "transparent",
    },
    [theme.breakpoints.down("md")]: {
      width: "20rem",
    },
  }),
});

export { useStyles };

import Utils from "../../../Utils";
import { ServiceProviderSearchModel, RFQProjectModel } from "../../../model";

export const serviceProviderSearchReducer = (
  state: ServiceProviderSearchModel = new ServiceProviderSearchModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SERVICE_PROVIDER_SERACH:
      return { ...state, ...action.payload };
    case Utils.ActionName.SERVICE_PROVIDER_SEARCH_RESET:
      return {
        serviceProviderSearch: [],
      };
    default:
      return state;
  }
};

export const refProjectReducer = (
  state: RFQProjectModel = new RFQProjectModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.RFQ_PROJECT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

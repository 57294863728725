import Utils from "../../Utils";
import {
  globalLoaderStart,
  globalLoaderEnd,
} from "../../reducer/globalReducer";
import { getClientJobsData } from "../dashboard/Home/Requests/action";
import { uploadFile } from "../../Utils/AwsFileUpload";
const getRequiredData = (data: any) => {
  return data.map((innerData: any) => innerData.companyDetail);
};

const getFiles = (data: any) => {
  return data.map((file: any) => {
    let name = file.url.substring(file.url.lastIndexOf("/") + 1);
    return { name, url: file.url, size: file.size };
  });
};

// Jobs details data for RFQ
export const getJobDetailsData = (jobId: any) => {
  return (dispatch: any) => {
    const queryString = `?clientId=${localStorage.getItem(
      "user_id"
    )}&jobId=${jobId}`;

    Utils.API.getApiCall(
      Utils.endPoint.jobDetails,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.JOB_DETAILS,
            payload: {
              jobDetailsData: data.data,
              customerData: getRequiredData(data.data[0].company_job_detail),
              files: getFiles(data.data[0].mediaFile),
            },
          });
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

// Job details data other than RFQ
export const getOtherJobDetailsData = (jobId: any, query?: string) => {
  return (dispatch: any) => {
    let queryString = `?clientId=${localStorage.getItem(
      "user_id"
    )}&jobId=${jobId}`;

    queryString = query ? `${queryString}&status=${query}` : queryString;

    Utils.API.getApiCall(
      Utils.endPoint.otherJobDetails,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          !query
            ? dispatch({
                type: Utils.ActionName.JOB_DETAILS,
                payload: {
                  jobDetailsData: data.data,
                  customerData: getRequiredData(
                    data.data[0].company_job_detail
                  ),
                  files: getFiles(data.data[0].mediaFile),
                },
              })
            : dispatch({
                type: Utils.ActionName.BOOKED_DETAILS,
                payload: {
                  bookedDetailsData: data.data,
                },
              });
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const getJobDetailsId = (jobId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.JOB_ID,
      payload: {
        jobId: jobId,
      },
    });
  };
};

export const changeTab = (value: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.TAB_VALUE,
      payload: {
        tabValue: value,
      },
    });
  };
};

export const deleteJob = (userIds: any) => {
  return (dispatch: any) => {
    const queryString = {
      jobIds: userIds,
      type: "RFQ",
    };
    dispatch(globalLoaderStart());
    Utils.API.postApiCall(
      Utils.endPoint.deleteJob,
      queryString,
      (res: any) => {
        const { data } = res;
        console.log(data);
        dispatch(getClientJobsData("1000"));
      },
      (err: any) => {
        const { data } = err;
        console.log(data);
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const getReviewSearchData = (searchValue: any) => {
  return (dispatch: any) => {
    let clientId = localStorage.getItem("user_id");
    let jobId = localStorage.getItem("myId");
    let queryString = `?pageNo=1&limit=10&searchKey=${searchValue}&clientId=${clientId}&jobId=${jobId}`;
    Utils.API.getApiCall(
      Utils.endPoint.serviceProviderSearch,
      queryString,
      (res: any) => {
        const { data } = res;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.JOB_DETAILS,
            payload: {
              jobDetailsData: data.data,
            },
          });
        }
      },
      (err: any) => {
        const { data } = err;
        console.log(data, "err");
      }
    );
  };
};

export const acceptdata =
  (reqJobId: any, jobId: any) => async (dispatch: any) => {
    const dataToSend = {
      clientId: localStorage.getItem("user_id"),
      reqJobId: reqJobId,
      jobId: jobId,
      status:
        // eslint-disable-next-line no-constant-condition
        localStorage.getItem("requestType") === "RFQ"
          ? "COMPLETED"
          : "IN_PROGRESS",
    };

    dispatch(globalLoaderStart());
    Utils.API.putApiCall(
      Utils.endPoint.acceptJob,
      dataToSend,
      (res: any) => {
        const { data } = res;
        if (data.statusCode === Utils.Constants.api_success_code.success) {
          Utils.showAlert(1, data.message || "Profile Updated Successfully");
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        console.dir(error);

        let { data } = error;
        Utils.showAlert(2, data.message || "An Unexpected Error Occured");
        dispatch(globalLoaderEnd());
      }
    );
  };

export const getCompleteDetailsData = (jobId: any, query: any) => {
  return (dispatch: any) => {
    const queryString = `?clientId=${localStorage.getItem(
      "user_id"
    )}&jobId=${jobId}&status=${query}`;

    Utils.API.getApiCall(
      Utils.endPoint.jobDetails,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.BOOKED_DETAILS,
            payload: {
              bookedDetailsData: data.data,
            },
          });
        } else {
          Utils.showAlert(2, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

// Comment API

export const getComment =
  (reqJobId: string, jobId: any, commentType?: string) =>
  (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, "Please check your internet connection!");
      return;
    }
    dispatch(globalLoaderStart());

    // let params = `?reqJobId=${reqJobId}&&jobId=${jobId}&limit=1000`;
    let params = `?${
      reqJobId !== "" ? `reqJobId=${reqJobId}&&` : ""
    }jobId=${jobId}&commentType=${commentType}&limit=1000`;

    Utils.API.getApiCall(
      Utils.endPoint.getComments,
      params,
      (response: any) => {
        const { data } = response;
        dispatch({
          type: Utils.ActionName.GET_COMMENTS,
          payload: {
            CommentsData: data?.data || [],
          },
        });
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export const addComments = (
  message: string,
  jobId: string,
  reqJobId: string,
  commentType?: string
) => {
  return (dispatch: any) => {
    const dataToSend = {
      comment: message,
      // ...(jobId !== "" && { jobId }),
      jobId,
      // reqJobId,
      ...(reqJobId !== "" && { reqJobId }),
      type: 1,
      commentType: commentType,
      // image:[""]
    };

    Utils.API.postApiCall(
      Utils.endPoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          dispatch(getComment(reqJobId, jobId, commentType));
          // window.location.reload();
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const addCommentsImages =
  (
    images: any,
    message: string,
    jobId: string,
    reqJobId: string,
    commentType?: string
  ) =>
  async (dispatch: any) => {
    console.log("dsfdhfjf", images);
    let imagesUrl = [];
    if (images.length > 0) {
      for (let el of images) {
        try {
          const imageData: any = await uploadFile(el.file);
          imagesUrl.push(imageData.Location);
        } catch (error) {
          Utils.showAlert(2, "Erroe occured while uploading an image");
          throw new Error("Error");
        }
      }
    }
    const dataToSend = {
      comment: message,
      // ...(jobId !== "" && { jobId }),
      jobId,
      ...(reqJobId !== "" && { reqJobId }),
      // reqJobId,
      type: 3,
      image: imagesUrl,
      commentType: commentType,
    };
    Utils.API.postApiCall(
      Utils.endPoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          dispatch(getComment(reqJobId, jobId, commentType));
          // window.location.reload();
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };

export const commentImages =
  (images: any, jobId: string, reqJobId: string, commentType?: string) =>
  async (dispatch: any) => {
    console.log("imagesimages", images);
    let imagesUrl = [];
    if (images.length > 0) {
      for (let el of images) {
        try {
          const imageData: any = await uploadFile(el?.file);
          imagesUrl.push(imageData.Location);
        } catch (error) {
          Utils.showAlert(2, "Erroe occured while uploading an image");
          throw new Error("Error");
        }
      }
    }
    const dataToSend = {
      jobId,
      // reqJobId,
      ...(reqJobId !== "" && { reqJobId }),
      type: 2,
      image: imagesUrl,
      commentType: commentType,
    };
    Utils.API.postApiCall(
      Utils.endPoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          dispatch(getComment(reqJobId, jobId, commentType));
          // window.location.reload();
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };

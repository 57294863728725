import API from "./api";
import showAlert from "./showAlert";
import endPoint from "./endPoint";
import ActionName from "./actionName";
import Pathname from "./pathname";
import constants from "./constants";
import CommonFunction from "./commonFunctions";

const Utils = {
  API: API,
  showAlert: showAlert,
  endPoint: endPoint,
  ActionName: ActionName,
  Pathname: Pathname,
  Constants: constants,
  CommonFunctions: CommonFunction,
};

export default Utils;

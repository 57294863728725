import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import GlobalLoader from "../../components/globalLoader";
import {
  globalLoaderEnd,
  hideGetStartedTooltipPermanent,
} from "../../reducer/globalReducer";
import Utils from "../../Utils";
import ActionName from "../../Utils/actionName";
import MainContainer from "../mainContainer/index";

interface Props {
  path: string;
  component: any;
  exact?: boolean;
}
const PrivateRoute = ({ component: Component, exact, ...rest }: Props) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const currentUser = () => {
    Utils.API.getApiCall(
      Utils.endPoint.profile,
      "",
      (data: any) => {
        // for get started checkbox Section

        let isCompanyProfileComplete =
          data?.data?.data?.isCompanyProfileComplete || false;
        let isBookingTradeService =
          data?.data?.data?.isBookingTradeService || false;
        let isInviteCoworker = data?.data?.data?.isInviteCoworker || false;
        let isFinancialOnboard = data?.data?.data?.isFinancialOnboard || false;

        if (
          localStorage.getItem("roles") === "ADMIN" ||
          localStorage.getItem("roles") === "SUPER_ADMIN"
        ) {
          isCompanyProfileComplete = data?.data?.data?.isCompanyProfileComplete;
          isBookingTradeService = data?.data?.data?.isBookingTradeService;
          isInviteCoworker = data?.data?.data?.isInviteCoworker;
          isFinancialOnboard = data?.data?.data?.isFinancialOnboard;
        }

        if (data?.data?.data?.userType === "CLIENT") {
          if (
            isCompanyProfileComplete &&
            isBookingTradeService &&
            isInviteCoworker &&
            isFinancialOnboard &&
            !data?.data?.data?.toolTip
          ) {
            dispatch(hideGetStartedTooltipPermanent());
          }
          dispatch({
            type: ActionName.GETSTARTED_STEPS_COMPANY,
            payload: {
              isShow: true,
              isCompanyProfileComplete,
              isBookingTradeService,
              isInviteCoworker,
              isFinancialOnboard,
            },
          });
        }
      },

      (err: any) => {
        if (err?.statusCode === 401) {
          localStorage.clear();
          Utils.showAlert(2, "Session Expired");
          history.push("/");
          dispatch(globalLoaderEnd());
        } else if (err?.statusCode === 500) {
          localStorage.clear();
          Utils.showAlert(2, "Session Expired");
          history.push("/");
          dispatch(globalLoaderEnd());
        }
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      currentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <GlobalLoader />
      <Route
        exact={Boolean(exact)}
        {...rest}
        render={(props) => (
          <React.Fragment>
            {localStorage.getItem("accessToken") ||
            sessionStorage.getItem("verify_access_Token") ? (
              <MainContainer>
                <Suspense fallback={""}>
                  <Component {...props} />
                </Suspense>
              </MainContainer>
            ) : (
              <Redirect to="/" />
            )}
          </React.Fragment>
        )}
      />
    </>
  );
};
export default PrivateRoute;

import { makeStyles } from "@mui/styles/";

const useStyles = makeStyles({
  imgContainer: () => ({
    "& img": {
      width: "153px",
      height: "46px",
    },
  }),
});

export { useStyles };

import Utils from "../../Utils/index";
import { SignUpModel, ProfileModel } from "../../model/index";
export const signupReducer = (
  state: SignUpModel = new SignUpModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SIGNUP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const profileReducer = (
  state: ProfileModel = new ProfileModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PROFILECOMPLETE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

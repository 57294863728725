import Utils from "../../Utils";
import { savedCompanyListingModel, CompanyDetailsModel } from "../../model";

export const CompanyClientListReducer = (
  state = {
    list: [],
    total: 0,
    pages: 0,
  },
  action: any
) => {
  switch (action?.type) {
    case "COMPANY_LIST_SUCCESS":
      return { ...state, ...action?.payload };

    default:
      return { ...state };
  }
};

export const savedCompanyListingReducer = (
  state: savedCompanyListingModel = new savedCompanyListingModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.SAVED_COMPANY_LISTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const companyDetailsReducer = (
  state: CompanyDetailsModel = new CompanyDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.COMAPNY_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getCompanyClientListState = (state: any) =>
  state["CompanyClientListReducer"];

import Utils from "../../../../Utils";
import { ProfileDetailsModel } from "../../../../model";

export const BusinessProfileReducer = (
  state = {
    businessProfilePicture: "",
    businessName: "",
    businessTagline: "",
    location: null,
  },
  action: any
) => {
  switch (action.type) {
    case "GET_CURRENT_BUSINESS_PROFILE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return { ...state };
  }
};

export const getBusinessProfileState = (state: any) =>
  state.BusinessProfileReducer;

export const getProfileState = (state: any) => state.profiledetailsdataReducer;

export const profiledetailsdataReducer = (
  state: ProfileDetailsModel = new ProfileDetailsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PROFILE_DETAILS_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

import Utils from "../../../../Utils";
import {
  globalLoaderStart,
  globalLoaderEnd,
} from "../../../../reducer/globalReducer";

export const getClientJobsData = (limit: any) => {
  return (dispatch: any, getState: any) => {
    const { pageNo } = getState().clientJobsTableReducer;
    const queryString = `?clientId=${
      localStorage.getItem("roles")
        ? localStorage.getItem("clientId")
        : localStorage.getItem("user_id")
    }&pageNo=${pageNo}&limit=${limit}`;

    dispatch(globalLoaderStart());
    Utils.API.getApiCall(
      Utils.endPoint.clientJobs,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.CLIENT_JOBS,
            payload: {
              clientJobsData: data.data,
            },
          });
        } else {
          Utils.showAlert(2, data.message);
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };
};

import { GlobalLoaderModal, DropDownListsModel } from "../model";
import Utils from "../Utils";

export const globalLoaderReducer = (
  state: GlobalLoaderModal = new GlobalLoaderModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const globalLoaderStart = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: true },
  };
};

export const globalLoaderEnd = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: false },
  };
};

export const dropDownReducer = (
  state: DropDownListsModel = new DropDownListsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.ROLES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return { ...state };
  }
};

export const getStartedModalReducer = (
  state: any = {
    open: false,
    toolTip: localStorage.getItem("toolTip") ? true : false,
  },
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GETSTARTED_MODAL_OPEN:
      return {
        ...state,
        open: true,
      };
    case Utils.ActionName.GETSTARTED_MODAL_CLOSE:
      return {
        ...state,
        open: false,
      };
    case Utils.ActionName.GETSTARTED_TOOLTIP_OPEN:
      return {
        ...state,
        toolTip: true,
      };
    case Utils.ActionName.GETSTARTED_TOOLTIP_CLOSE:
      return {
        ...state,
        toolTip: false,
      };

    default:
      return state;
  }
};

// actions...

// get Started actions

export const getStartedModalOpen = () => {
  return {
    type: Utils.ActionName.GETSTARTED_MODAL_OPEN,
  };
};

export const getStartedModalClose = () => {
  return {
    type: Utils.ActionName.GETSTARTED_MODAL_CLOSE,
  };
};

export const getStartedToolTipOpen = () => {
  return {
    type: Utils.ActionName.GETSTARTED_TOOLTIP_OPEN,
  };
};

export const getStartedToolTipClose = () => {
  return {
    type: Utils.ActionName.GETSTARTED_TOOLTIP_CLOSE,
  };
};

export const hideGetStartedTooltipPermanent = () => (dispatch: any) => {
  Utils.API.postApiCall(
    Utils.endPoint.tooltip,
    { toolTip: true },
    () => {
      dispatch(getStartedToolTipClose());
      localStorage.removeItem("toolTip");
    },
    (error: any) => {
      console.log("error tooltip", error);
      Utils.showAlert(2, "Something went wrong");
    }
  );
};

export const GetStartedTooltipRemindMeLater = () => (dispatch: any) => {
  Utils.API.postApiCall(
    Utils.endPoint.tooltip,
    { toolTip: false },
    () => {
      dispatch(getStartedToolTipClose());
      localStorage.removeItem("toolTip");
    },
    (error: any) => {
      console.log("error tooltip", error);
      Utils.showAlert(1, "Something went wrong");
    }
  );
};

export const getStartedModalState = (state: any) =>
  state.getStartedModalReducer;
